//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import Errors from '@/shared/error/errors';
// import { i18n } from '@/i18n';


import { UserModel } from '@/modules/auth/user-model';
import { FormSchema } from '@/shared/form/form-schema';
const { fields } = UserModel;
const formSchema = new FormSchema([
  fields.firstName,
  fields.lastName,
  fields.fullName,
  fields.phoneNumber,
  fields.avatarsProfile,
  fields.countryCode,
  fields.lang, 
  fields.accountType,
  fields.email,
]);

export default {
  name: 'app-profile-form-page',
  components: {
    VuePhoneNumberInput,
  },

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
      CCode: 'SA',
      defaultImg:'../../../../public/images/defaultAvatar.svg',
      url: '../../../../public/images/defaultAvatar.svg',
      phoneNumber: '051 234 5678',
      TRansObject: {
        countrySelectorLabel: 'Code Country',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'Phone Number',
        example: 'Example :',
      },
      TRansObjectAr: {
        countrySelectorLabel: 'كود البلد',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'رقم الجوال',
        example: 'مثال :',
      },
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      saveLoading: 'auth/loadingUpdateProfile',
      currentLanguageCode: 'layout/currentLanguageCode',
      is_screen_xs: 'layout/is_screen_xs',
    }),
    fields() {
      return fields;
    },
    isRTL() {
      return this.currentLanguageCode == 'ar'
    },
    isCCode() {
      if (!this.model.countryCode) {
        return this.CCode;
      } else {
        return this.model.countryCode;
      }
    },
  },
  async created() {
    this.model = formSchema.initialValues(this.currentUser);
    console.log(this.model)
    let  avatar = [];
    if(process.env.VUE_APP_ENVIRONMENT  === 'staging' || process.env.VUE_APP_ENVIRONMENT  == 'localhost') { 
      avatar = [{
        id: '',
        name: 'defaultAvatar.svg',
        privateUrl: '',
        publicUrl: "https://firebasestorage.googleapis.com/v0/b/rahal-7e911.appspot.com/o/rahalAdmin%2Fpublic%2FdefaultAvatar.svg?alt=media&token=141064ad-a117-4463-80dc-1b32ec0b9987",
      }];
    } else if (process.env.VUE_APP_ENVIRONMENT === 'production') {
      avatar = [{
        id: '',
        name: 'defaultAvatar.svg',
        privateUrl: '',
        publicUrl: "https://firebasestorage.googleapis.com/v0/b/rahal-7e911.appspot.com/o/rahalAdmin%2Fpublic%2FdefaultAvatar.svg?alt=media&token=141064ad-a117-4463-80dc-1b32ec0b9987",
      }];
    }
    // to check if there is no avatar then set the default one 
    if(!this.model.avatars[0]) {
      this.model.avatars = avatar 
    }
  },
  methods: {
    ...mapActions({
      doUpdateProfile: 'auth/doUpdateProfile',
    }),
     i18n(key, args) {
      return this.$t(key, args);
    },
    doReset() {
      this.model = formSchema.initialValues(this.currentUser);
    },
    onError(error) {
      Errors.showMessage(error);
      this.loading = false;
    },
    onUpdate(data) {
      this.CCode = data.countryCode;
    },
    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }
      this.model.countryCode = this.CCode;
      this.model.lang = this.currentLanguageCode;
      this.model.accountType = this.currentUser.accountType
      const values = formSchema.cast(this.model);
      delete values.email
      this.doUpdateProfile(values);
    },
  },
};
